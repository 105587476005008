import { ReactNode } from "react";
import styled from "styled-components";
import { ScaleResultType } from "@shared/types";
import { withTranslation } from "react-i18next";

interface RowProps {
  background: string;
  clickable: boolean;
}
const Row = styled.tr<RowProps>`
  background-color: ${(props) => props.background};
  width: 100%;
  height: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
    opacity: ${(props) => (props.clickable ? 0.8 : 1.0)};
  }
`;

const Column = styled.td`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  box-shadow: inset 0 -1px 0 0 var(--light-blue-grey), inset -1px 0 0 0 #ffffff;
  padding: 0 10px;
`;

interface Props {
  columns: ReactNode[];
  highlight: boolean;
  event: any;
}
function EventComponent(props: Props) {
  const isBalerEvent = props.event.type === ScaleResultType.BALER_START;

  let color = "ice-blue";
  if (isBalerEvent) {
    color = "seafoam-blue-with-opacity";
  } else if (props.highlight) {
    color = "ice-blue-dark";
  }
  return (
    <Row background={`var(--${color})`} clickable={false} onClick={() => {}}>
      {props.columns.map((column, index) => (
        <Column key={index}>{column}</Column>
      ))}
    </Row>
  );
}

export const Event = withTranslation()(EventComponent);
