const apiError = [
  // Admin API
  "admin.patchGroup.integrationUsernameInUse",
  "admin.postGroup.integrationUsernameInUse",
  "admin.deleteGroup.smart",
  "admin.deleteGroup.scaleTags",
  "admin.putGroupLicenses.smart",
  "admin.postUser.email",
  "admin.postUser.nameExists",
  "admin.devices.task.create.invalidSerial",
  "admin.devices.errorTask.create.duplicateName",
  "admin.devices.errorTask.update.duplicateName",
  // Password API
  "password.verify.notRegistered",
  // Scale API
  "scale.createUserGroup.duplicateName",
  "scale.createUserGroup.duplicateCustomerId",
  "scale.updateUserGroup.duplicateName",
  "scale.updateUserGroup.duplicateCustomerId",
  // Users API
  "user.serviceTool.login.notAllowed",
  "user.serviceTool.login.notFound",
  "user.serviceTool.login.expired",
  "user.login.lockout",
  "user.login.invalidOTP",
] as const;

const getErrorCode = (error: ApiError) => {
  const errorIdx = apiError.indexOf(error).toString();
  const zeroPaddedIdx = `000${errorIdx}`.substr(errorIdx.length - 1);

  return `E${zeroPaddedIdx}`;
};

export { apiError, getErrorCode };
export type ApiError = (typeof apiError)[number];
