import * as TYPES from "../constants/actionTypes";
import { convertToDeviceType } from "../utils/utils";
import {
  DeviceStatus,
  DeviceType,
  FillState,
  MachineType,
  SelectableColumns,
} from "../interfaces/types";

export type DeviceState = {
  elements: DeviceType[];
  isFetching: boolean;
  query: string;
  loadedDevices: number;
  statusFilter: DeviceStatus[];
  fillLevelFilter: FillState[];
  machineTypeFilter: MachineType[];
  clusterDevices: boolean;
  clusterDevicesZoom: boolean;
  sortColumn: SelectableColumns;
  sortOrder: number;
  history: any[];
  loadingHistory: boolean;
};
const initialState: DeviceState = {
  elements: [],
  isFetching: false,
  query: "",
  loadedDevices: 0,
  statusFilter: [],
  fillLevelFilter: [],
  machineTypeFilter: [],
  clusterDevices: true,
  clusterDevicesZoom: false,
  sortColumn: SelectableColumns.SERIAL_NUMBER,
  sortOrder: 1,
  history: [],
  loadingHistory: false,
};

const devices = (state = initialState, action: any): DeviceState => {
  switch (action.type) {
    case TYPES.FETCH_DEVICES: {
      return { ...state, isFetching: action.payload.initialFetch };
    }
    case TYPES.FETCH_DEVICES_SUCCESS: {
      const { devices: devicesRaw } = action.payload;

      const devices = convertToDeviceType(devicesRaw);

      return {
        ...state,
        isFetching: false,
        loadedDevices: devices.length,
        elements: devices,
      };
    }
    case TYPES.FETCH_DEVICES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case TYPES.SEARCH_TERM_UPDATED: {
      const query = action.payload.query.trim();
      return {
        ...state,
        query,
      };
    }
    case TYPES.FILTER_DEVICES_BY_STATUS: {
      const statusFilter = action.payload.statuses;

      return {
        ...state,
        statusFilter,
      };
    }
    case TYPES.FILTER_DEVICES_BY_FILL_LEVEL: {
      const fillLevelFilter = action.payload.fillLevels;

      return {
        ...state,
        fillLevelFilter,
      };
    }
    case TYPES.FILTER_DEVICES_BY_MACHINE_TYPE: {
      const machineTypes = action.payload.machineTypes;

      return {
        ...state,
        machineTypeFilter: machineTypes,
      };
    }
    case TYPES.CLUSTER_DEVICES_ON_MAP: {
      const cluster = action.payload.cluster;
      return { ...state, clusterDevices: cluster };
    }
    case TYPES.CLUSTER_DEVICES_ON_MAP_ZOOM: {
      const cluster = action.payload.cluster;
      return { ...state, clusterDevicesZoom: cluster };
    }
    case TYPES.SORT_DEVICES: {
      const { sortColumn, sortOrder } = action.payload;

      return {
        ...state,
        sortColumn,
        sortOrder,
      };
    }
    case TYPES.PATCH_DEVICE_DETAIL_SUCCESS: {
      const { device } = action.payload;
      const deviceRaw = convertToDeviceType([device])[0];
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === deviceRaw.serialNumber) {
          device = Object.assign(device, deviceRaw);
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS: {
      const { serialNumber, fillLevelMode } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            fillLevelMode,
          });
        }
        return device;
      });
      return {
        ...state,
        elements,
      };
    }
    case TYPES.PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS: {
      const { serialNumber, scaleTotalWeightAfterLastEmptied } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            scaleTotalWeightAfterLastEmptied,
          });
        }
        return device;
      });
      return {
        ...state,
        elements,
      };
    }
    case TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS: {
      const { serialNumber, scaleTotalWeightEmptyingLimit } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            scaleTotalWeightEmptyingLimit: scaleTotalWeightEmptyingLimit,
          });
        }
        return device;
      });
      return {
        ...state,
        elements,
      };
    }
    case TYPES.UPDATE_BALE_COUNTER: {
      const { baleCounter, serialNumber } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          if (baleCounter) {
            device = Object.assign(device, { baleCounter });
          }
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.RESET_BALE_COUNTER: {
      const { serialNumber } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            baleCounter: { ...device.baleCounter, balesReady: 0 },
          });
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.RESET_BALE_COUNTER_FAILURE: {
      const { serialNumber, oldBalesReady } = action.payload;
      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            baleCounter: { ...device.baleCounter, balesReady: oldBalesReady },
          });
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.CHANGE_BALES_READY: {
      const { serialNumber, newBalesReady } = action.payload;

      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            baleCounter: { ...device.baleCounter, balesReady: newBalesReady },
          });
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.CHANGE_BALES_READY_FAILURE: {
      const { serialNumber, oldBalesReady } = action.payload;

      let elements = state.elements.map((device: DeviceType) => {
        if (device.serialNumber === serialNumber) {
          device = Object.assign(device, {
            baleCounter: { ...device.baleCounter, balesReady: oldBalesReady },
          });
        }
        return device;
      });

      return {
        ...state,
        elements,
      };
    }
    case TYPES.GET_DEVICE_HISTORY:
      return { ...state, history: [], loadingHistory: true };
    case TYPES.GET_DEVICE_HISTORY_SUCCESS:
      return { ...state, history: action.payload, loadingHistory: false };
    case TYPES.GET_DEVICE_HISTORY_FAILURE:
      return { ...state, loadingHistory: false };
    case TYPES.PATCH_DEVICE_DETAIL_FAILURE:
    default: {
      return state;
    }
  }
};
export default devices;
