import { actionChannel, call, put, take } from "redux-saga/effects";
import * as api from "../api/scale";
import * as TYPES from "../constants/actionTypes";
import { Notify } from "../components/Common/Notify";
import { getNotificationByAction } from "../utils/utils";

/**
 * Fetch scale events. IF fetch fails puts action FETCH_SCALE_EVENTS_FAILURE. For success passes data
 * through FETCH_SCALE_EVENTS_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchScaleEvents(action: any) {
  try {
    const result = yield call(api.getScaleEvents, action);
    yield put({
      type: TYPES.FETCH_SCALE_EVENTS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.FETCH_SCALE_EVENTS_FAILURE));
    yield put({ type: TYPES.FETCH_SCALE_EVENTS_FAILURE });
  }
}

/**
 * Fetch all scales. IF fetch fails puts action FETCH_SCALES_FAILURE. For success passes data
 * through FETCH_SCALES_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchScales(action: any) {
  try {
    const result = yield call(api.getScales, action);
    yield put({
      type: TYPES.FETCH_SCALES_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.FETCH_SCALES_FAILURE));
    yield put({ type: TYPES.FETCH_SCALES_FAILURE });
  }
}

export function* updateScale(action: any) {
  try {
    yield call(api.patchScale, action);
    yield put({
      type: TYPES.ADMIN_UPDATE_SCALE_SUCCESS,
      payload: action.payload,
    });
    Notify.success(getNotificationByAction(TYPES.ADMIN_UPDATE_SCALE_SUCCESS));
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.ADMIN_UPDATE_SCALE_FAILURE));
  }
}

/**
 * Fetches all available scale firmwares. IF fetch fails puts action FETCH_SCALES_FAILURE. For success passes data
 * through FETCH_SCALE_FIRMWARES_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchFirmwares(action: any) {
  try {
    const result = yield call(api.getFirmwares, action);
    yield put({
      type: TYPES.FETCH_SCALE_FIRMWARES_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.FETCH_SCALE_FIRMWARES_FAILURE));
    yield put({ type: TYPES.FETCH_SCALE_FIRMWARES_FAILURE });
  }
}

/**
 * Sets scale firmware upload file
 *
 * @export
 * @param {*} action
 */
export function* postScaleFirmwareFile(action: any) {
  try {
    yield call(api.postScaleUploadFile, action);
    yield put({
      type: TYPES.POST_SCALE_UPLOAD_FILE_SUCCESS,
      payload: action.payload,
    });
    Notify.success(
      getNotificationByAction(TYPES.POST_SCALE_UPLOAD_FILE_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.POST_SCALE_UPLOAD_FILE_FAILURE });
  }
}

function* updateScaleTag(action: any) {
  try {
    yield call(api.patchScaleTag, action);
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.UPDATE_SCALE_TAG_FAILURE));
    yield put({
      type: TYPES.UPDATE_SCALE_TAG_FAILURE,
      payload: action.payload,
    });
  }
}

/**
 * Ensure only one scale tag update is applied at a time by creating a channel for scale tag update
 * actions, and taking one message at a time from that channel. This ensures that we can reliably
 * restore the old scale tag value if an error occurs during an optimistic scale tag update.
 */
export function* watchForScaleTagUpdates() {
  const updateChannel = yield actionChannel(TYPES.UPDATE_SCALE_TAG);
  while (true) {
    const action = yield take(updateChannel);
    yield call(updateScaleTag, action);
  }
}
