import { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { clusterOptions, mapOptions } from "../constants/options";
import * as TYPES from "../constants/actionTypes";
import { apiIsLoaded } from "../utils/mapUtils";
import { Cluster } from "../utils/cluster";
import {
  DeviceType,
  MarkerType,
  FillState,
  MachineType,
  DeviceRoute,
} from "../interfaces/types";
import { getFillLevelAsString } from "../utils/utils";
import { GoogleMarker } from "../components/GoogleMarker";
import { GoogleClusterMarker } from "../components/GoogleClusterMarker";
import { GoogleMap } from "../components/GoogleMap";
import { Bounds } from "google-map-react";
import { RouteMarker } from "../components/RouteMarker";
import { getPointGroupMarker } from "../components/GooglePointGroupMarker";
import { hasErrorOrWarning } from "../utils/deviceStatus";
import {
  containerClusterEngineSelector,
  deviceClusterEngineSelector,
  visibleContainersSelector,
  visibleDevicesSelector,
} from "../selectors/devices";

const mapDispatchToProps = (dispatch: any) => {
  return {
    onMapParametersChanged: (
      zoom: number,
      bounds: Bounds,
      center: number[]
    ) => {
      dispatch({
        type: TYPES.MAP_PARAMETERS_UPDATED,
        payload: {
          zoom: zoom,
          bounds: bounds,
          center: center,
        },
      });
    },
    clusterOnZoom: (cluster: boolean) => {
      dispatch({
        type: TYPES.CLUSTER_DEVICES_ON_MAP_ZOOM,
        payload: { cluster },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    devices: visibleDevicesSelector(state),
    clusterEngine: deviceClusterEngineSelector(state),
    loadedDevices: state.devices.loadedDevices,
    clusterDevices: state.devices.clusterDevices,
    clusterDevicesZoom: state.devices.clusterDevicesZoom,
    zoom: state.map.zoom,
    bounds: state.map.bounds,
    center: state.map.center,
    skipDevices: visibleContainersSelector(state),
    skipClusterEngine: containerClusterEngineSelector(state),
    visibleRoutes: state.map.visibleRoutes,
  };
};

interface MapProps {
  onMapParametersChanged(zoom: number, bounds: Bounds, center: number[]): void;
  clusterOnZoom(cluster: boolean): void;
  onEditDevice(device: DeviceType): void;
  onShowAnalytics(device: DeviceType): void;
  devices: Array<any>;
  clusterEngine: Cluster<DeviceType>;
  loadedDevices: number;
  clusterDevices: boolean;
  clusterDevicesZoom: boolean;
  zoom: number;
  bounds?: Bounds;
  center: number[];
  deviceTypesOnMap: MachineType;
  skipDevices: Array<DeviceType>;
  skipClusterEngine: Cluster<DeviceType>;
  visibleRoutes: Array<DeviceRoute>;
}

interface MapState {
  map: google.maps.Map | undefined;
  maps: any;
}

class MapConnected extends Component<MapProps, MapState> {
  public constructor(props: MapProps) {
    super(props);

    this.state = {
      map: undefined,
      maps: undefined,
    };
  }
  private handleMapChange = ({ zoom, bounds, center }: any) => {
    this.props.onMapParametersChanged(zoom, bounds, [center.lat, center.lng]);
  };
  /**
   * Handles that map zoom is shown either "near" or outside. Where map is zoomed is defined using
   * current zoom state
   */
  private handleClickToZoom = (center: number[]): void => {
    if (this.props.zoom < mapOptions.zoomOut && this.props.bounds) {
      this.props.onMapParametersChanged(
        mapOptions.zoomInTarget,
        this.props.bounds,
        center
      );
    } else if (this.props.bounds !== undefined) {
      // Zoom out
      this.props.onMapParametersChanged(
        mapOptions.zoomOutTarget,
        this.props.bounds,
        this.props.center
      );
    }
  };
  private handleClusterClicked = (id: number): void => {
    let clusterEngine: Cluster<DeviceType> = this.props.clusterEngine;
    if (this.props.deviceTypesOnMap === MachineType.Skip) {
      clusterEngine = this.props.skipClusterEngine;
    }
    const zoom = clusterEngine.getClusterExpansionZoom(id);
    const cluster = clusterEngine.getCluster(id);
    let center = this.props.center;
    if (cluster) {
      center = [
        cluster.geometry.coordinates[1],
        cluster.geometry.coordinates[0],
      ];
    }
    if (this.props.bounds !== undefined) {
      this.props.onMapParametersChanged(zoom, this.props.bounds, center);
    }
  };
  /**
   * Warning is active if device state is either error or warning or fill level is full or overfull
   */
  private warningActive(device: DeviceType): boolean {
    return (
      hasErrorOrWarning(device) ||
      device.fillLevel === FillState.Full ||
      device.fillLevel === FillState.OverFull
    );
  }
  private getPlaces(): Array<any> {
    let places: any[] = [];
    if (
      this.props.bounds &&
      this.props.clusterEngine &&
      this.props.skipClusterEngine
    ) {
      let clusterEngine: Cluster<DeviceType> = this.props.clusterEngine;
      if (this.props.deviceTypesOnMap === MachineType.Skip) {
        clusterEngine = this.props.skipClusterEngine;
      }
      const clusters = clusterEngine.getClusters(
        this.props.bounds,
        this.props.zoom
      );
      for (const cluster of clusters) {
        if (cluster.properties && cluster.properties.point_count > 1) {
          if (!this.props.clusterDevices || !this.props.clusterDevicesZoom) {
            // User has selected that do not cluster points.
            const points = clusterEngine.getLeaves(
              cluster.properties.cluster_id
            );
            for (const point of points) {
              const place: any = this.createPoint(point);
              places.push(place);
            }
          } else {
            const place = {
              homeLatitude: cluster.geometry.coordinates[1],
              homeLongitude: cluster.geometry.coordinates[0],
              devices: cluster.properties.point_count,
              id: cluster.properties.cluster_id,
              type: MarkerType.Cluster,
              warning: cluster.properties.warning,
            };
            places.push(place);
          }
        } else {
          // We have a point - not a cluster
          const place: any = this.createPoint(cluster);
          places.push(place);
        }
      }
    }

    // Show clusters if current map zoom is smaller then show point groups max zoom
    const clusterOnZoom =
      this.props.zoom < clusterOptions.showPointGroupsMaxZoom;
    if (this.props.clusterDevicesZoom !== clusterOnZoom) {
      this.props.clusterOnZoom(clusterOnZoom);
    }

    // Show point groups only when max zoom
    if (this.props.zoom >= clusterOptions.showPointGroupsMaxZoom) {
      return this.createPointGroups(places);
    }
    return places;
  }

  private createPointGroups(places: any): any {
    return places.reduce((accum: any, place: any) => {
      const found = accum.find((p: any) => {
        const pLat = p.homeLatitude ? p.homeLatitude : p.latitude;
        const placeLat = place.homeLatitude
          ? place.homeLatitude
          : place.latitude;
        const pLng = p.homeLongitude ? p.homeLongitude : p.longitude;
        const placeLng = place.homeLongitude
          ? place.homeLongitude
          : place.longitude;
        const pSerialNumber = p.device ? p.device.serialNumber : p.serialNumber;
        const placeSerialNumber = place.device
          ? place.device.serialNumber
          : place.serialNumber;

        return (
          pLat === placeLat &&
          pLng === placeLng &&
          pSerialNumber !== placeSerialNumber
        );
      });
      if (found) {
        if (!found.points) {
          found.points = [this.createPointForPointGroup(found)];
        }
        found.points.push(this.createPointForPointGroup(place));
        found.type = MarkerType.PointGroup;
      } else {
        accum.push(place);
      }
      return accum;
    }, []);
  }

  private createPointForPointGroup(point: any): any {
    return {
      ...point,
      fillLevel: getFillLevelAsString(point.fillLevel),
      warning: this.warningActive(point.device),
      handleClickToZoom: this.handleClickToZoom,
      onEditDevice: this.props.onEditDevice,
    };
  }

  private createPoint(point: any): any {
    return {
      homeLatitude: point.geometry.coordinates[1],
      homeLongitude: point.geometry.coordinates[0],
      fillLevel: point.properties.fillLevel,
      status: point.properties.status,
      type: MarkerType.Point,
      device: point.properties.object,
    };
  }
  private getMarker(place: any): JSX.Element | JSX.Element[] {
    if (place.type === MarkerType.Point) {
      // If place has a serialNumber it is itself a device
      const dev =
        place.serialNumber && place.serialNumber !== "" ? place : place.device;
      return (
        <GoogleMarker
          key={uuidv4()}
          lat={place.homeLatitude ? place.homeLatitude : place.latitude}
          lng={place.homeLongitude ? place.homeLongitude : place.longitude}
          warning={this.warningActive(dev)}
          fillLevel={getFillLevelAsString(place.fillLevel)}
          device={dev}
          zoomIn={this.props.zoom < mapOptions.zoomOut}
          handleClickToZoom={this.handleClickToZoom}
          onEditDevice={this.props.onEditDevice}
          onShowAnalytics={this.props.onShowAnalytics}
          type={dev.machineType ? dev.machineType : MachineType.Compactor}
        />
      );
    } else if (place.type === MarkerType.RoutePoint) {
      return (
        <RouteMarker
          key={uuidv4()}
          lat={place.routePoint.latitude}
          lng={place.routePoint.longitude}
          date={place.routePoint.date}
        />
      );
    } else if (place.type === MarkerType.PointGroup) {
      return getPointGroupMarker({
        lat: place.homeLatitude,
        lng: place.homeLongitude,
        points: place.points,
        zoom: this.props.zoom,
        warning: place.points.some((point: any) => point.warning),
        map: this.state.map,
        maps: this.state.maps,
        onShowAnalytics: this.props.onShowAnalytics,
      });
    }
    return (
      <GoogleClusterMarker
        key={uuidv4()}
        id={place.id}
        devices={place.devices}
        lat={place.homeLatitude}
        lng={place.homeLongitude}
        active={false}
        onClicked={this.handleClusterClicked}
        zoom={this.props.zoom}
        warning={place.warning}
      />
    );
  }
  public createGoogleMap = (): JSX.Element => {
    const places = this.getPlaces();
    // Insert device route points if there are any
    if (this.props.visibleRoutes && this.props.visibleRoutes.length > 0) {
      for (const deviceRoute of this.props.visibleRoutes) {
        for (const markerPoint of deviceRoute.route) {
          places.push({
            type: MarkerType.RoutePoint,
            device: deviceRoute.device,
            routePoint: markerPoint,
          });
        }
      }
    }
    return (
      <GoogleMap
        defaultZoom={mapOptions.defaultZoom}
        defaultCenter={mapOptions.defaultCenter}
        zoom={this.props.zoom}
        center={this.props.center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }: any) =>
          this.initMap(map, maps, places)
        }
        onChange={this.handleMapChange}
      >
        {places.map((place: any) => this.getMarker(place))}
      </GoogleMap>
    );
  };
  public render(): JSX.Element {
    return <>{this.createGoogleMap()}</>;
  }

  private initMap(map: any, maps: any, places: any): void {
    apiIsLoaded(map, maps, places, this.props.bounds, this.props.zoom);
    this.setState({ map, maps });
  }
}

export const Map = connect(mapStateToProps, mapDispatchToProps)(MapConnected);
