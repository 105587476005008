import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SecondaryButtonSmall } from "../../../components/Common/Button";
import { Table } from "../../../components/Common/Table";
import { Spinner } from "../../../components/Spinner";
import {
  adminHGDevicesSelector,
  adminIProtoxiDevicesSelector,
  adminRetrofitLightDevicesSelector,
  isHGDevicesLoadingSelector,
  isIProtoxiDevicesLoadingSelector,
  isRetrofitLightDevicesLoadingSelector,
} from "../../../selectors/admin";
import * as TYPES from "../../../constants/actionTypes";

function HGDevicesTable({ devices }: any) {
  const data = useMemo(() => devices, [devices]);
  const columns = useMemo(() => {
    return [
      { Header: "H&G Serial number", accessor: "hgId", width: 2 },
      { Header: "Serial number", accessor: "serialNumber", width: 2 },
      { Header: "Group", accessor: "groupName", width: 2 },
      {
        Header: "",
        id: "selected",
        width: 1,
        Cell: ({ row }: any) => (
          <Link to={`/admin/devices/device-management/hg/${row.original.hgId}`}>
            <SecondaryButtonSmall>Edit</SecondaryButtonSmall>
          </Link>
        ),
      },
    ];
  }, []);

  return (
    <Table
      data={data}
      enableFilters
      infiniteScroll
      initialState={{ sortBy: [{ id: "hgId" }] }}
      sortable
      columns={columns}
    />
  );
}

function RetrofitLightDevicesTable({ devices }: any) {
  const data = useMemo(() => devices, [devices]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Retrofit Light Serial number",
        accessor: "retrofitLightDeviceId",
        width: 2,
      },
      { Header: "Serial number", accessor: "serialNumber", width: 2 },
      { Header: "Group", accessor: "groupName", width: 2 },
      {
        Header: "",
        id: "selected",
        width: 1,
        Cell: ({ row }: any) => (
          <Link
            to={`/admin/devices/device-management/retrofit-light/${row.original.retrofitLightDeviceId}`}
          >
            <SecondaryButtonSmall>Edit</SecondaryButtonSmall>
          </Link>
        ),
      },
    ];
  }, []);

  return (
    <Table
      data={data}
      enableFilters
      infiniteScroll
      initialState={{ sortBy: [{ id: "serialNumber" }] }}
      columns={columns}
    />
  );
}

function IProtoxiDevicesTable({ devices }: any) {
  const data = useMemo(() => devices, [devices]);

  return (
    <Table
      data={data}
      enableFilters
      infiniteScroll
      initialState={{ sortBy: [{ id: "serialNumber" }] }}
      columns={[
        {
          Header: "iProtoxi Serial number",
          accessor: "iprotoxi_qrcode",
          width: 2,
        },
        { Header: "Paired device", accessor: "Device.serialNumber", width: 2 },
        { Header: "Group", accessor: "Group.name", width: 2 },
        {
          Header: "",
          id: "selected",
          width: 1,
          Cell: ({ row }: any) => (
            <Link
              // TODO: Make this view
              to={`/admin/devices/device-management/iprotoxi/${row.original.id}`}
            >
              <SecondaryButtonSmall>Edit</SecondaryButtonSmall>
            </Link>
          ),
        },
      ]}
    />
  );
}

const Heading = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

const Page = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: var(--ice-blue);
`;
const DevicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  background-color: var(--background);

  > a {
    margin-bottom: 2rem;
  }

  > .selector {
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  > table {
    width: 100%;
  }
`;

export function DeviceManagement() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const isHGLoading = useSelector(isHGDevicesLoadingSelector);
  const isRetrofitLightLoading = useSelector(
    isRetrofitLightDevicesLoadingSelector
  );
  const isIProtoxiLoading = useSelector(isIProtoxiDevicesLoadingSelector);

  const HGDevices = useSelector(adminHGDevicesSelector);
  const retrofitLightDevices = useSelector(adminRetrofitLightDevicesSelector);
  const IProtoxiDevices = useSelector(adminIProtoxiDevicesSelector);

  useEffect(() => {
    for (const type of [
      TYPES.GET_ALL_HG_DEVICES,
      TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES,
      TYPES.GET_ALL_IPROTOXI_DEVICES,
    ]) {
      dispatch({ type, payload: { token } });
    }
  }, [dispatch, token]);

  return (
    <Page>
      <DevicesContainer>
        <Heading>H&G management</Heading>
        {isHGLoading ? <Spinner /> : <HGDevicesTable devices={HGDevices} />}
      </DevicesContainer>
      <DevicesContainer>
        <Heading>Retrofit Light management</Heading>
        {isRetrofitLightLoading ? (
          <Spinner />
        ) : (
          <RetrofitLightDevicesTable devices={retrofitLightDevices} />
        )}
      </DevicesContainer>
      <DevicesContainer>
        <Heading>IProtoxi management</Heading>
        {isIProtoxiLoading ? (
          <Spinner />
        ) : (
          <IProtoxiDevicesTable devices={IProtoxiDevices} />
        )}
      </DevicesContainer>
    </Page>
  );
}
