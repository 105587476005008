import Axios, { AxiosPromise } from "axios";
import * as apiTypes from "@shared/apiTypes";

/**
 * Fetches devices from server.
 *
 * @export
 * @param {action} is fetch devices action with payload which contains token and
 * optional limit and page query and offset params
 * @returns {AxiosPromise}
 */
export function getDevices(action: any): AxiosPromise {
  return Axios.get(`${process.env.REACT_APP_LATEST_API}/devices`, {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  });
}
/**
 * Fetches given devices (given serial numbers) routes from server.
 *
 * @export
 * @param {*} action is fetch devices routes action with payload token and devices serial number string array
 * @returns {AxiosPromise}
 */
export function getDevicesRoutes(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/devices/routes";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  let data = {
    serialNumbers: action.payload.devices,
  };
  return Axios.post(request, data, config);
}

export function getDeviceImages(action: any): AxiosPromise {
  const serial = encodeURIComponent(action.payload.serialNumber);
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };

  return Axios.get(
    `${process.env.REACT_APP_LATEST_API}/devices/${serial}/images`,
    config
  );
}

export function postDeviceDetail(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/devices/" +
    encodeURIComponent(action.payload.device.serialNumber);
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.post(request, action.payload.device, config);
}

export function patchDeviceTotalWeight(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/devices/" +
    encodeURIComponent(action.payload.serialNumber) +
    "/scale/total-weight";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.patch(
    request,
    {
      scaleTotalWeightAfterLastEmptied:
        action.payload.scaleTotalWeightAfterLastEmptied,
    },
    config
  );
}

export function patchFillLevelMode(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/devices/" +
    encodeURIComponent(action.payload.serialNumber) +
    "/fill-level-mode";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.patch(
    request,
    {
      fillLevelMode: action.payload.fillLevelMode,
    },
    config
  );
}

export function patchDeviceEmptyingLimit(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/devices/" +
    encodeURIComponent(action.payload.serialNumber) +
    "/scale/emptying-limit";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.patch(
    request,
    {
      scaleTotalWeightEmptyingLimit:
        action.payload.scaleTotalWeightEmptyingLimit,
    },
    config
  );
}

export function putBaleCounter(action: any): AxiosPromise {
  let request = `${
    process.env.REACT_APP_LATEST_API
  }/devices/${encodeURIComponent(action.payload.serialNumber)}/bale-counter`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.put(request, action.payload.counter, config);
}

export function deleteBaleCounter(action: any): AxiosPromise {
  let request = `${
    process.env.REACT_APP_LATEST_API
  }/devices/${encodeURIComponent(action.payload.serialNumber)}/bale-counter`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.delete(request, config);
}

export function postBaleCounterBalesReady(action: any): AxiosPromise {
  let request = `${
    process.env.REACT_APP_LATEST_API
  }/devices/${encodeURIComponent(
    action.payload.serialNumber
  )}/bale-counter/change`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.post(
    request,
    { balesReady: action.payload.newBalesReady },
    config
  );
}
export function postBaleCounterReset(action: any): AxiosPromise {
  let request = `${
    process.env.REACT_APP_LATEST_API
  }/devices/${encodeURIComponent(
    action.payload.serialNumber
  )}/bale-counter/reset`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.post(request, undefined, config);
}

export function getDeviceHistory(action: any): AxiosPromise {
  const { serialNumber, period, page, pageSize } = action.payload;

  const request = `${
    process.env.REACT_APP_LATEST_API
  }/devices/${encodeURIComponent(
    serialNumber
  )}/history?period=${period}&page=${page}&pageSize=${pageSize}`;
  const config = {
    headers: { Authorization: `Bearer ${action.payload.token}` },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.get(request, config);
}

export function updateOwnerGroup(action: any): AxiosPromise | Object {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/devices/updateOwnerGroup";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.post(url, action.payload.data, config);
}

export function updateOperatorGroup(action: any): AxiosPromise | Object {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/devices/updateOperatorGroup";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.post(url, action.payload.data, config);
}

export function updateActiveState(action: any): AxiosPromise | Object {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/devices/updateActiveState";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.post(url, action.payload.data, config);
}

export async function getDeviceErrorTaskList(data: { token: string }) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks`;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
  };
  const res = await Axios.get<apiTypes.DeviceErrorTaskListApi>(url, config);
  return res.data;
}

export async function getDeviceErrorTaskDetail(data: {
  token: string;
  taskId: number;
}) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks/${data.taskId}`;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
  };
  const res = await Axios.get<apiTypes.DeviceErrorTaskDetailApi>(url, config);
  return res.data;
}

export async function postDeviceErrorTask(data: {
  token: string;
  task: apiTypes.DeviceErrorTaskCreateApi;
}) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks`;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
  };
  const res = await Axios.post<undefined>(url, data.task, config);
  return res.data;
}

export async function patchDeviceErrorTask(data: {
  token: string;
  task: apiTypes.DeviceErrorTaskUpdateApi;
  taskId: number;
}) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks/${data.taskId}`;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
  };
  const res = await Axios.patch<undefined>(url, data.task, config);
  return res.data;
}

export async function deleteDeviceErrorTask(data: {
  token: string;
  taskId: number;
}) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks/${data.taskId}`;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
  };
  const res = await Axios.delete<undefined>(url, config);
  return res.data;
}

export async function getDeviceErrorTaskExecutionHistory(data: {
  token: string;
  start: Date;
  end: Date;
}) {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks/executionHistory`;
  const res = await Axios.get<apiTypes.DeviceErrorTaskExecutionHistoryApi>(
    url,
    {
      headers: { Authorization: "Bearer " + data.token },
      timeout: 20000,
      params: { start: data.start, end: data.end },
    }
  );
  return res.data;
}

export async function getDeviceErrorTaskAffectedDevices(data: {
  token: string;
  groupId: number | undefined;
}) {
  if (data.groupId === undefined) {
    throw new Error("invalid group id");
  }

  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/errorTasks/affectedDevices`;
  const res = await Axios.get<apiTypes.DeviceErrorTaskAffectedDevicesApi>(url, {
    headers: { Authorization: "Bearer " + data.token },
    timeout: 20000,
    params: { groupId: data.groupId },
  });

  return res.data;
}

export function getAllHGDevices(action: any): AxiosPromise {
  const url = process.env.REACT_APP_LATEST_API + "/admin/hg-devices";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}

export function patchHGDevice(action: any): AxiosPromise {
  const url =
    process.env.REACT_APP_LATEST_API +
    `/admin/hg-devices/${action.payload.data.hgId}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.patch(url, action.payload.data, config);
}

export function getAllRetrofitLightDevices(action: any): AxiosPromise {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/retrofit-light-devices";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}

export function patchRetrofitLightDevice(action: any): AxiosPromise {
  const url =
    process.env.REACT_APP_LATEST_API +
    `/admin/retrofit-light-devices/${action.payload.data.retrofitLightDeviceId}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.patch(url, action.payload.data, config);
}

export function getAllIProtoxiDevices(action: any): AxiosPromise {
  const url = process.env.REACT_APP_LATEST_API + "/admin/iprotoxi-devices";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}

export function patchIProtoxiDevice(action: any): AxiosPromise {
  const url =
    process.env.REACT_APP_LATEST_API +
    `/admin/iprotoxi-devices/${action.payload.iProtoxiDeviceId}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.patch(url, action.payload.data, config);
}

export function getAllDeviceTasks(action: any): AxiosPromise {
  const url = process.env.REACT_APP_LATEST_API + "/admin/devices/tasks";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}

export function postDeviceTasks(action: any): AxiosPromise {
  const url = process.env.REACT_APP_LATEST_API + "/admin/devices/tasks";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.post(url, action.payload.tasksData, config);
}

export function patchDeviceTasks(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/tasks/${action.payload.taskId}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.patch(url, action.payload.taskData, config);
}

export function deleteDeviceTask(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/tasks/${action.payload.taskId}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.delete(url, config);
}

export function getPressureData(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/pressure-data/${action.payload.id}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}

export function getDeviceFillLevels(action: any): AxiosPromise {
  const serial = encodeURIComponent(action.payload.serialNumber);
  const url = `${process.env.REACT_APP_LATEST_API}/admin/devices/${serial}/fill-levels`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  return Axios.get(url, config);
}
