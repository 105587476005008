import { toast, Slide } from "react-toastify";

const NotifyComponent = ({
  message,
  detail,
}: {
  message?: string;
  detail?: string;
}) => {
  return (
    <div>
      <div>{message}</div>
      {detail && detail !== "" && (
        <p style={{ color: "#ddd", fontSize: "0.8em", marginBottom: "0" }}>
          {detail}
        </p>
      )}
    </div>
  );
};

interface NotifyOptions {
  // TODO: Implement the rest of the options as needed, or find out what the type is for toast options (https://fkhadra.github.io/react-toastify/api/toast/)
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  type?: "error" | "success" | "info" | "warning";
  toastId?: string; // Notifications with the same ID will overwrite each other
  autoClose?: number | false;
  closeOnClick?: boolean;
  detail?: string;
}

const createNotification = (message: string, options: NotifyOptions) => {
  // Destructure the options object to run some logic on it
  const {
    detail,
    toastId = message,
    type = "success",
    ...toastOptions
  } = options;

  if (toast.isActive(toastId)) {
    return toast.update(toastId, { transition: Slide });
  } else {
    return toast(<NotifyComponent message={message} detail={detail} />, {
      toastId,
      type,
      ...toastOptions,
    });
  }
};

export const Notify = {
  success: (message: string, options: NotifyOptions = {}) => {
    createNotification(message, { ...options, type: "success" });
  },
  error: (message: string, options: NotifyOptions = {}) => {
    createNotification(message, {
      ...options,
      type: "error",
      autoClose: false,
      closeOnClick: false,
    });
  },
};
