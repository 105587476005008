import * as actionTypes from "../constants/actionTypes";
import _ from "lodash/fp";
import {
  AllLicensesApi,
  GroupLicensesApi,
  DeviceFillLevelsApi,
  PressureDataApi,
  AdminUserApi,
} from "@shared/apiTypes";
import {
  PressureAnomaly,
  PressureAnomalyType,
  Survey,
} from "src/interfaces/types";

type PressureDataEntry = {
  serialNumber: string;
  fillLevels: DeviceFillLevelsApi[] | undefined;
  fillLevelsLoading: boolean;
  pressureData: { [id: number]: PressureDataApi };
  pressureDataLoading: boolean;
};
export type AdminState = {
  isLoaded: boolean;
  users: AdminUserApi[];
  user: AdminUserApi | undefined;
  licenses: {
    isLoading: boolean;
    data: { [groupId: number]: GroupLicensesApi };
  };
  pressureData: {
    devices: {
      [serialNumber: string]: PressureDataEntry;
    };
  };
  anomalies: {
    data: PressureAnomaly[];
    types: PressureAnomalyType[];
  };
  isSubmitting: boolean;
  scales: any[];
  firmwares: any[];
  hgDevices: any;
  retrofitLightDevices: any;
  iprotoxiDevices: any;
  dashboard: any;
  surveys: {
    data: Survey[];
    globalSettings: { [key: string]: any };
  };
};

function getInitialPressureDataObject(serialNumber: string): PressureDataEntry {
  return {
    serialNumber,
    fillLevels: undefined,
    fillLevelsLoading: false,
    pressureData: {},
    pressureDataLoading: false,
  };
}

const initialState = {
  isLoaded: true,
  users: [],
  user: undefined,
  group: undefined,
  licenses: { isLoading: false, data: {} },
  pressureData: { isLoading: false, devices: {} },
  anomalies: {
    data: [],
    types: [],
  },
  isSubmitting: false,
  scales: [] as any[],
  firmwares: [] as any[],
  hgDevices: {},
  retrofitLightDevices: {},
  iprotoxiDevices: {},
  dashboard: { offline: [], faultMode: [] },
  surveys: {
    data: [],
    globalSettings: {},
  },
};

const admin = (state: AdminState = initialState, action: any): AdminState => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_FOR_ADMIN: {
      return { ...state, isLoaded: false };
    }
    case actionTypes.FETCH_USERS_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_USERS_FOR_ADMIN_FAILURE: {
      return { ...state, isLoaded: true };
    }
    case actionTypes.FETCH_USER_FOR_ADMIN: {
      return { ...state, isLoaded: false };
    }
    case actionTypes.FETCH_USER_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_USER_FOR_ADMIN_FAILURE: {
      return { ...state, isLoaded: true };
    }

    case actionTypes.SEND_REGISTER_LINK_TO_USER: {
      return state;
    }
    case actionTypes.SEND_REGISTER_LINK_TO_USER_SUCCESS: {
      return state;
    }
    case actionTypes.SEND_REGISTER_LINK_TO_USER_FAILURE: {
      return state;
    }

    case actionTypes.CREATE_USER_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.CREATE_USER_FOR_ADMIN_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.CREATE_USER_FOR_ADMIN_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.UPDATE_USER_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.UPDATE_USER_FOR_ADMIN_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.DELETE_USER_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.DELETE_USER_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        users: state.users.filter(
          (user: any) => user.id !== action.payload.userId
        ),
      };
    }
    case actionTypes.DISABLE_USER_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.DISABLE_USER_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        users: state.users.filter(
          (user: any) => user.id !== action.payload.userId
        ),
      };
    }
    case actionTypes.UPDATE_USER_FOR_ADMIN_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.ENABLE_USER_MFA_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.ENABLE_USER_MFA_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        users: state.users.map((user) =>
          user.id === action.payload.userId
            ? { ...user, mfaEnabled: true }
            : user
        ),
      };
    }
    case actionTypes.ENABLE_USER_MFA_FOR_ADMIN_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.DISABLE_USER_MFA_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.DISABLE_USER_MFA_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        users: state.users.map((user) =>
          user.id === action.payload.userId
            ? { ...user, mfaEnabled: false }
            : user
        ),
      };
    }
    case actionTypes.DISABLE_USER_MFA_FOR_ADMIN_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.DELETE_GROUP_FOR_ADMIN: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.DELETE_GROUP_FOR_ADMIN_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.DELETE_GROUP_FOR_ADMIN_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.UPDATE_OWNER_GROUP_FOR_DEVICES: {
      return { ...state, isSubmitting: true };
    }
    case actionTypes.UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case actionTypes.FETCH_SCALES_SUCCESS: {
      const scales = action.payload.scales;
      return { ...state, scales: scales };
    }
    case actionTypes.FETCH_SCALES_FAILURE: {
      return state;
    }
    case actionTypes.FETCH_DASHBOARD_SUCCESS: {
      const dashboard = action.payload.dashboard;
      return { ...state, dashboard: dashboard };
    }
    case actionTypes.FETCH_DASHBOARD_FAILURE: {
      return state;
    }
    case actionTypes.ADMIN_UPDATE_SCALE_SUCCESS: {
      const { scaleId, data } = action.payload;
      return {
        ...state,
        scales: state.scales.map((scale) =>
          scale.id === scaleId ? { ...scale, ...data } : scale
        ),
      };
    }
    case actionTypes.FETCH_SCALE_FIRMWARES_SUCCESS: {
      const firmwares = action.payload.firmwares;
      return { ...state, firmwares: firmwares };
    }
    case actionTypes.FETCH_SCALE_FIRMWARES_FAILURE: {
      return state;
    }
    case actionTypes.POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS: {
      const { deviceId, issueType, checked } = action.payload;
      const dashboard = {
        ...state.dashboard,
        [issueType]: state.dashboard[issueType].map((device: any) => {
          if (device.id === deviceId) {
            device.inTheWorks = checked;
          }
          return device;
        }),
      };
      return {
        ...state,
        dashboard,
      };
    }
    case actionTypes.POST_SCALE_UPLOAD_FILE_SUCCESS: {
      const { scales: updatedScaleSerials, firmware } = action.payload;
      return {
        ...state,
        scales: state.scales.map((scale) =>
          updatedScaleSerials.includes(scale.scale_serial_number)
            ? { ...scale, file_to_update: firmware }
            : scale
        ),
      };
    }
    case actionTypes.GET_ALL_HG_DEVICES: {
      return _.set("hgDevices.isLoading", true, state);
    }
    case actionTypes.GET_ALL_HG_DEVICES_SUCCESS: {
      return {
        ...state,
        hgDevices: {
          isLoading: false,
          data: { ...action.payload },
        },
      };
    }
    case actionTypes.GET_ALL_HG_DEVICES_FAILURE: {
      return _.set("hgDevices.isLoading", false, state);
    }
    case actionTypes.PATCH_HG_DEVICE: {
      return _.set("hgDevices.isLoading", true, state);
    }
    case actionTypes.PATCH_HG_DEVICE_SUCCESS: {
      return {
        ...state,
        hgDevices: {
          isLoading: false,
          data: { ...action.payload },
        },
      };
    }
    case actionTypes.PATCH_HG_DEVICE_FAILURE: {
      return _.set("hgDevices.isLoading", false, state);
    }
    case actionTypes.GET_ALL_RETROFIT_LIGHT_DEVICES: {
      return _.set("retrofitLightDevices.isLoading", true, state);
    }
    case actionTypes.GET_ALL_RETROFIT_LIGHT_DEVICES_SUCCESS: {
      return {
        ...state,
        retrofitLightDevices: {
          isLoading: false,
          data: { ...action.payload },
        },
      };
    }
    case actionTypes.GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE: {
      return _.set("retrofitLightDevices.isLoading", false, state);
    }
    case actionTypes.PATCH_RETROFIT_LIGHT_DEVICE: {
      return _.set("retrofitLightDevices.isLoading", true, state);
    }
    case actionTypes.PATCH_RETROFIT_LIGHT_DEVICE_SUCCESS: {
      return {
        ...state,
        retrofitLightDevices: {
          isLoading: false,
          data: { ...action.payload },
        },
      };
    }
    case actionTypes.PATCH_RETROFIT_LIGHT_DEVICE_FAILURE: {
      return _.set("retrofitLightDevices.isLoading", false, state);
    }
    case actionTypes.GET_ALL_IPROTOXI_DEVICES: {
      return _.set("iprotoxiDevices.isLoading", true, state);
    }
    case actionTypes.GET_ALL_IPROTOXI_DEVICES_SUCCESS: {
      return {
        ...state,
        iprotoxiDevices: {
          isLoading: false,
          data: { ...action.payload },
        },
      };
    }
    case actionTypes.GET_ALL_IPROTOXI_DEVICES_FAILURE: {
      return _.set("iprotoxiDevices.isLoading", false, state);
    }
    case actionTypes.GET_ALL_LICENSES: {
      return _.set("licenses.isLoading", true, state);
    }
    case actionTypes.GET_ALL_LICENSES_SUCCESS: {
      const licensesKeyVals = (action.payload.licenses as AllLicensesApi).map(
        (license) => [license.groupId, license] as const
      );
      return {
        ...state,
        licenses: {
          isLoading: false,
          data: { ...Object.fromEntries(licensesKeyVals) },
        },
      };
    }
    case actionTypes.GET_ALL_LICENSES_FAILURE: {
      return _.set("licenses.isLoading", false, state);
    }
    case actionTypes.GET_GROUP_LICENSES: {
      return _.set("licenses.isLoading", true, state);
    }
    case actionTypes.GET_GROUP_LICENSES_SUCCESS: {
      return {
        ...state,
        licenses: {
          isLoading: false,
          data: { [action.payload.groupId]: action.payload.licenses },
        },
      };
    }
    case actionTypes.GET_GROUP_LICENSES_FAILURE: {
      return _.set("licenses.isLoading", false, state);
    }
    case actionTypes.UPDATE_GROUP_LICENSES: {
      return _.set("licenses.isLoading", true, state);
    }
    case actionTypes.UPDATE_GROUP_LICENSES_SUCCESS: {
      return {
        ...state,
        licenses: {
          isLoading: false,
          data: {
            ...state.licenses.data,
            [action.payload.groupId]: action.payload.licenses,
          },
        },
      };
    }
    case actionTypes.UPDATE_GROUP_LICENSES_FAILURE: {
      return _.set("licenses.isLoading", false, state);
    }
    case actionTypes.GET_PRESSURE_DATA: {
      const { serialNumber } = action.payload;

      const pressureData =
        state.pressureData.devices[serialNumber] ??
        getInitialPressureDataObject(serialNumber);
      const updatedPressureData = {
        ...pressureData,
        pressureDataLoading: true,
      };

      return _.set(
        `pressureData.devices[${serialNumber}]`,
        updatedPressureData,
        state
      );
    }
    case actionTypes.GET_PRESSURE_DATA_SUCCESS: {
      const { pressureData, serialNumber } = action.payload;
      const stateWithPressure = _.set(
        `pressureData.devices[${serialNumber}].pressureData[${pressureData.id}]`,
        action.payload.pressureData,
        state
      );
      return _.set(
        `pressureData.devices[${serialNumber}].pressureDataLoading`,
        false,
        stateWithPressure
      );
    }
    case actionTypes.GET_PRESSURE_DATA_FAILURE: {
      const { serialNumber } = action.payload;
      return _.set(
        `pressureData.devices[${serialNumber}].pressureDataLoading`,
        false,
        state
      );
    }
    case actionTypes.FETCH_ANOMALIES: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          data: [],
        },
        isLoaded: false,
      };
    }
    case actionTypes.FETCH_ANOMALIES_SUCCESS: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          data: action.payload,
        },
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_ANOMALIES_FAILURE: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          data: [],
        },
        isLoaded: true,
      };
    }
    case actionTypes.UPDATE_ANOMALIES: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.UPDATE_ANOMALIES_SUCCESS: {
      const updatedAnomalyIds = action.payload.map(
        (a: PressureAnomalyType) => a.id
      );
      const updatedAnomalies = action.payload.filter(
        (a: PressureAnomalyType) => !updatedAnomalyIds.includes(a.id)
      );
      return {
        ...state,
        isLoaded: true,
        anomalies: {
          ...state.anomalies,
          data: updatedAnomalies,
        },
      };
    }
    case actionTypes.UPDATE_ANOMALIES_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_ANOMALY_TYPES: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          types: [],
        },
      };
    }
    case actionTypes.FETCH_ANOMALY_TYPES_SUCCESS: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          types: action.payload,
        },
      };
    }
    case actionTypes.FETCH_ANOMALY_TYPES_FAILURE: {
      return {
        ...state,
      };
    }
    case actionTypes.CREATE_ANOMALY_TYPE: {
      return {
        ...state,
      };
    }
    case actionTypes.CREATE_ANOMALY_TYPE_SUCCESS: {
      return {
        ...state,
        anomalies: {
          ...state.anomalies,
          types: [...state.anomalies.types, action.payload],
        },
      };
    }
    case actionTypes.CREATE_ANOMALY_TYPE_FAILURE: {
      return {
        ...state,
      };
    }
    case actionTypes.UPDATE_ANOMALY_TYPE: {
      return {
        ...state,
      };
    }
    case actionTypes.UPDATE_ANOMALY_TYPE_SUCCESS: {
      const filteredTypes = state.anomalies.types.filter(
        (t: PressureAnomalyType) => {
          return t.id === action.payload.id;
        }
      );
      return {
        ...state,
        isLoaded: true,
        anomalies: {
          ...state.anomalies,
          types: [...filteredTypes, action.payload],
        },
      };
    }
    case actionTypes.UPDATE_ANOMALY_TYPE_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.DELETE_ANOMALY_TYPE: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.DELETE_ANOMALY_TYPE_SUCCESS: {
      const filteredTypes = state.anomalies.types.filter(
        (t: PressureAnomalyType) => {
          return t.id !== action.payload.id;
        }
      );
      return {
        ...state,
        isLoaded: true,
        anomalies: {
          ...state.anomalies,
          types: filteredTypes,
        },
      };
    }
    case actionTypes.DELETE_ANOMALY_TYPE_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.GET_DEVICE_FILL_LEVELS: {
      const { serialNumber } = action.payload;

      const pressureData =
        state.pressureData.devices[serialNumber] ??
        getInitialPressureDataObject(serialNumber);
      const updatedPressureData = {
        ...pressureData,
        fillLevelsLoading: true,
      };

      return _.set(
        `pressureData.devices[${serialNumber}]`,
        updatedPressureData,
        state
      );
    }
    case actionTypes.GET_DEVICE_FILL_LEVELS_SUCCESS: {
      const { fillLevels, serialNumber } = action.payload;
      const stateWithFillLevels = _.set(
        `pressureData.devices[${serialNumber}].fillLevels`,
        fillLevels,
        state
      );
      return _.set(
        `pressureData.devices[${serialNumber}].fillLevelsLoading`,
        false,
        stateWithFillLevels
      );
    }
    case actionTypes.GET_DEVICE_FILL_LEVELS_FAILURE: {
      const { serialNumber } = action.payload;
      return _.set(
        `pressureData.devices[${serialNumber}].fillLevelsLoading`,
        false,
        state
      );
    }
    case actionTypes.FETCH_SURVEYS: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.FETCH_SURVEYS_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_SURVEYS_SUCCESS: {
      const surveys = action.payload;
      return {
        ...state,
        surveys: {
          globalSettings: state.surveys.globalSettings,
          data: surveys,
        },
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_SURVEY_GLOBAL_SETTINGS: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.FETCH_SURVEY_GLOBAL_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.FETCH_SURVEY_GLOBAL_SETTINGS_SUCCESS: {
      const globalSettings = action.payload;
      return {
        ...state,
        surveys: {
          data: state.surveys.data,
          globalSettings,
        },
        isLoaded: true,
      };
    }
    case actionTypes.UPDATE_SURVEY_GLOBAL_SETTINGS: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.UPDATE_SURVEY_GLOBAL_SETTINGS_SUCCESS: {
      const globalSettings = action.payload;
      return {
        ...state,
        surveys: {
          data: state.surveys.data,
          globalSettings,
        },
        isLoaded: true,
      };
    }
    case actionTypes.UPDATE_SURVEY_GLOBAL_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.CREATE_SURVEY: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.CREATE_SURVEY_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.CREATE_SURVEY_SUCCESS: {
      return {
        ...state,
        surveys: {
          data: [...state.surveys.data, action.payload.data],
          globalSettings: state.surveys.globalSettings,
        },
        isLoaded: true,
      };
    }
    case actionTypes.UPDATE_SURVEY: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.UPDATE_SURVEY_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.UPDATE_SURVEY_SUCCESS: {
      const updatedSurvey = action.payload.data;
      return {
        ...state,
        surveys: {
          data: state.surveys.data.map((s: Survey) =>
            // Update the modified survey in the client side store
            s.id === updatedSurvey.id ? updatedSurvey : s
          ),
          globalSettings: state.surveys.globalSettings,
        },
        isLoaded: true,
      };
    }
    case actionTypes.DELETE_SURVEY: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.DELETE_SURVEY_FAILURE: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case actionTypes.DELETE_SURVEY_SUCCESS: {
      const deletedSurveyId = action.payload.data;
      return {
        ...state,
        surveys: {
          data: state.surveys.data.filter(
            (s: Survey) =>
              // Remove the modified survey in the client side store
              s.id !== deletedSurveyId
          ),
          globalSettings: state.surveys.globalSettings,
        },
        isLoaded: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default admin;
