import { call, put } from "redux-saga/effects";
import * as api from "../api/dashboard";
import * as TYPES from "../constants/actionTypes";
import { Notify } from "../components/Common/Notify";
import { getNotificationByAction } from "../utils/utils";

/**
 * Fetch dashboard data. IF fetch fails puts action FETCH_DASHBOARD_FAILURE. For success passes data
 * through FETCH_DASHBOARD_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchDashboard(action: any) {
  try {
    const result = yield call(api.getDashboard, action);
    yield put({
      type: TYPES.FETCH_DASHBOARD_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.FETCH_DASHBOARD_FAILURE));
    yield put({ type: TYPES.FETCH_DASHBOARD_FAILURE });
  }
}
/**
 * Sets scale firmware upload file
 *
 * @export
 * @param {*} action
 */
export function* postDashboardWorkLogUpdate(action: any) {
  try {
    yield call(api.postDashboardWorkLogUpdate, action);
    yield put({
      type: TYPES.POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS,
      payload: action.payload,
    });
    Notify.success(
      getNotificationByAction(TYPES.POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.POST_DASHBOARD_WORK_LOG_UPDATE_FAILURE });
  }
}
