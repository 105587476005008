import { possibleDeviceErrorTaskStates } from "./constants";

export enum DeviceStates {
  STATUS_UNKNOWN = 0x0000,
  PARAMS_UNSAFE = 0x0001,
  OIL_LEVEL_LOW = 0x0002,
  OIL_TEMP_HIGH = 0x0004,
  OIL_LEAKAGE_DETECTED = 0x0008,
  MOTOR_TEMP_HIGH = 0x0010,
  CONTAINER_NOT_PRESENT = 0x0020,
  DOOR_OPEN_SW_ERROR = 0x0040,
  ESTOP_PRESSED = 0x0080,
  RESET_REQUIRED_AFTER_ESTOP = 0x0100,
  NON_CRITICAL_SAFETY_SWITCH = 0x0200,
  MACHINE_IS_OVERFULL = 0x0400,
  FRONT_BLOCK_ERROR = 0x0800,
  MACHINE_IS_UNSAFE = 0x1000,
  SERVICE_IS_DUE = 0x2000,
  MACHINE_OPERATIONS_BLOCKED = 0x4000,
  WIRE_HAS_RUN_OUT = 0x8000,
  NO_ERRORS_DETECTED = 0xffff,
  MACHINE_FAULT = 0x00010000,
  MOTOR_PROTECT = 0x00020000,
  OIL_LEVEL_AND_TEMP = 0x00040000,
  DOOR_OPEN = 0x00080000,
  BACK_BLOCK_DETECT_WARNING = 0x00100000,
  BACK_BLOCK_DETECT_ERROR = 0x00200000,
  INPUT_TIMING_ERROR = 0x00400000,
  TRAVEL_TIMES_ERROR = 0x00800000,
}

export enum ScaleResultType {
  SCALE_WEIGHT = 0,
  BALER_START = 1,
}

export enum ScaleButton {
  NONE = 0,
  UP = 1,
  DOWN = 2,
  BOTH = 3,
}

export enum DeviceTaskType {
  FILL_LEVEL_WEEKDAY_NOTIFICATION = 0,
}

export enum FillLevel {
  UNKNOWN = 110,
  EMPTY = 0,
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  ALMOST_FULL = 99,
  FULL = 100,
  OVERFULL = 101,
}

export function percentToFillLevel(fillLevelPercent: number): FillLevel {
  if (fillLevelPercent >= 0 && fillLevelPercent < 0.25) {
    return FillLevel.EMPTY;
  } else if (fillLevelPercent >= 0.25 && fillLevelPercent < 0.5) {
    return FillLevel.LEVEL_1;
  } else if (fillLevelPercent >= 0.5 && fillLevelPercent < 0.75) {
    return FillLevel.LEVEL_2;
  } else if (fillLevelPercent >= 0.75 && fillLevelPercent < 1) {
    return FillLevel.ALMOST_FULL;
  } else {
    return FillLevel.UNKNOWN;
  }
}

export enum DeviceFillLevelTask {
  LEVEL_1 = FillLevel.LEVEL_1,
  LEVEL_2 = FillLevel.LEVEL_2,
}

export enum PressureDataErrorType {
  PISTON_PERCENT_TOO_LOW = 0,
  NO_FULL_DETECT_PRESSURE = 1,
  UNKWNOWN = 2,
  NEGATIVE_FILL_LEVEL = 3,
  INVALID_PRESS_DATA = 4,
}

export enum RetrofitLightInputModes {
  ALMOST_FULL = "Almost Full",
  FULL = "Full",
  EMERGENCY_STOP_ACTIVATED = "Emergency Stop Active",
  CONTAINER_NOT_PRESENT = "Container Not Present",
  OIL_LEVEL_LOW = "Oil level Low",
  SAFETY_FUNCTION_ACTIVE = "Safety Function Active",
}

export type SocketIOEventName = "deviceUpdated";

export type DeviceErrorTaskStates =
  (typeof possibleDeviceErrorTaskStates)[number];

export enum SurveyActions {
  TRIGGERED = 0,
  OPENED = 1,
  ANSWERED = 2,
  DISMISSED = 3,
}

export enum SurveyType {
  SURVEY_ONBOARDING = 0,
  SURVEY_ENGAGEMENT_CSAT = 1,
  // SURVEY_ENGAGEMENT_CES = 2,  // Same as CSAT
  SURVEY_ENGAGEMENT_NPS = 3,
  SURVEY_ACTION_BASED = 4,
  // SURVEY_SUPPORT = 5,  // Unused, will be added at a later date
}

// These are global survey settings that can be modified in the SMART admin portal.
export enum SurveySettingsKey {
  // Deactivates all surveys
  DISABLE_ALL_SURVEYS = "disableAllSurveys",
  // Cool-off time before any other survey can be shown after one has been answered or dismissed
  // Also known as "response buffer"
  GLOBAL_COOLDOWN_TIME = "globalCooldownTime",
  // Time before a survey should "expire" if it is never opened by the user
  NONINTERACTION_EXPIRY = "noninteractionExpiry",
}

export type SurveyGlobalSettingsObject = {
  [SurveySettingsKey.DISABLE_ALL_SURVEYS]?: boolean;
  [SurveySettingsKey.GLOBAL_COOLDOWN_TIME]?: SurveyInterval;
  [SurveySettingsKey.NONINTERACTION_EXPIRY]?: string;
};

export enum SurveyIntervalUnitType {
  SESSIONS = "sessions",
  DAYS = "days",
}

export type SurveyInterval = {
  time: number;
  unit: SurveyIntervalUnitType;
};

// When a survey is answered, we need to pass some parameters to zef.fi's API
// in order to add the user's email address to the response. There is currently
// no other way to include fields to an embedded survey.
export type SurveyAnswerParameters = {
  answererId: string; // Respondent id, returned from zef.fi when a survey is submitted
  linkKey: string; // Survey key
  team: string; // Some kind of identifier, returned from zef.fi
  fields?: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
};
