import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Modal } from "./Common/Modal";

interface FeedbackModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const FEEDBACK_FORM_URL =
  "https://forms.clickup.com/9004083921/f/8cayuph-27852/RU48I906H0QHZG3XT4";

const FeedbackContainer = styled.div`
  padding: 20px 0;
  height: 100%;
`;

export function FeedbackModal(props: FeedbackModalProps) {
  const { t } = useTranslation();

  const content: JSX.Element = (
    <FeedbackContainer>
      <iframe
        title="Feedback form"
        className="clickup-embed clickup-dynamic-height"
        src={`${FEEDBACK_FORM_URL}?nocache=${new Date().getTime()}`}
        width="100%"
        height="100%"
        style={{ background: "transparent", border: "1px solid #ccc" }}
      />
    </FeedbackContainer>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      content={content}
      height="98%"
      title={t("header.userMenu.sendFeedback", "Send Feedback")}
    />
  );
}
