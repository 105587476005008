import styled from "styled-components";

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--ice-blue);
  white-space: pre-wrap;
`;

export function DesktopPrivacyPolicy() {
  return (
    <Page>
      <h1>Privacy Statement and Cookie Policy</h1>
      <h2>Privacy Statement</h2>
      <p>
        This Privacy Statement explains our practices, including your choices,
        regarding the collection, use, and disclosure of certain information,
        including your personal information, by Europress Company.
      </p>
      <h2>Contacting Us</h2>
      <p>
        If you have general questions about your account or how to contact
        Customer Service for assistance, please visit our company web page at
        www.europress.fi . The data controller of your personal information is
        Europress Group Oy., which also provides the service to you. Please note
        that if you contact us to assist you, for your safety and ours we may
        need to authenticate your identity before fulfilling your request.
      </p>
      <h2>Collection of Information</h2>
      <p>We receive and store information about you such as:</p>
      <ul>
        <li>
          <b>Information you provide to us:</b> We collect information you
          provide to us which includes:
          <ul>
            <li>
              your name, email address, address or postal code and telephone
              number. We collect this information in a number of ways, including
              when you enter it while using our service, interact with our
              Customer Service, or participate in surveys or marketing
              promotions;
            </li>
            <li>
              information when you choose to change account settings, set
              preferences in Your Account, or otherwise provide information to
              us through our service or elsewhere.
            </li>
          </ul>
        </li>
        <li>
          <b>Information we collect automatically:</b> We collect information
          about you and your use of our service, as well as information
          regarding your computer or other device used to access our service
          (such as mobile devices). This information includes:
          <ul>
            <li>
              your activity on the Europress SMART service, such as account
              administration, device history browsing and search queries;
            </li>
            <li>
              details of your interactions with Customer Service, such as the
              date, time and reason for contacting us, transcripts of any chat
              conversations, and if you call us, your phone number and call
              recordings;
            </li>
            <li>
              device IDs or other unique identifiers; device and software
              characteristics (such as type and configuration), connection
              information, statistics on page views, referral URLs, IP address
              (which may tell us your general location), browser and standard
              web server log information;
            </li>
            <li>
              information collected via the use of cookies, web beacons and
              other technologies.
            </li>
          </ul>
        </li>
        <li>
          Information from other sources: We might supplement the information
          described above with information we obtain from other sources,
          including from both online and offline data providers. Such
          supplemental information could include demographic data, interest
          based data, and Internet browsing behavior.
        </li>
      </ul>
      <h2>Use of Information</h2>
      <p>
        We use information to provide, analyze, administer, enhance and
        personalize our services and marketing efforts, to process your
        registration, your orders and to communicate with you on these and other
        topics.
      </p>
      <h2>Disclosure of Information</h2>
      <p>
        We disclose your information for certain purposes and to third parties,
        as described below:
      </p>
      <ul>
        <li>
          <b>Service Providers:</b> We use other companies, agents or
          contractors ("Service Providers") to perform services on our behalf or
          to assist us with the provision of services to you. For example, we
          engage Service Providers to provide marketing, advertising,
          communications, infrastructure and IT services, to personalize and
          optimize our service, to process credit card transactions or other
          payment methods, to provide customer service, to collect debts, to
          analyze and enhance data (including data about users' interactions
          with our service), and to process and administer consumer surveys. In
          the course of providing such services, these Service Providers may
          have access to your personal or other information. We do not authorize
          them to use or disclose your personal information except in connection
          with providing their services.
        </li>
        <li>
          <b>Protection of Europress and others:</b> Europress and its Service
          Providers may disclose and otherwise use your personal and other
          information where we or they reasonably believe such disclosure is
          needed to (a) satisfy any applicable law, regulation, legal process,
          or governmental request, (b) enforce applicable terms of use,
          including investigation of potential violations thereof, (c) detect,
          prevent, or otherwise address illegal or suspected illegal activities
          (including payment fraud), security or technical issues, or (d)
          protect against harm to the rights, property or safety of Europress,
          its users or the public, as required or permitted by law.
        </li>
        <li>
          <b>Business transfers:</b> In connection with any reorganization,
          restructuring, merger or sale, or other transfer of assets, we will
          transfer information, including personal information, provided that
          the receiving party agrees to respect your personal information in a
          manner that is consistent with our Privacy Statement.
        </li>
      </ul>
      <p>
        Whenever in the course of sharing information we transfer personal
        information to countries outside of the European Economic Area and other
        regions with comprehensive data protection laws, we will ensure that the
        information is transferred in accordance with this Privacy Statement and
        as permitted by the applicable laws on data protection.
      </p>
      <h2>Access to Your Account and Profiles</h2>
      <p>
        In order to provide you with ease of access to your account and to help
        administer the Europress service, we use technology that enables us to
        recognize you as the account holder and provide you with direct access
        to your account without requiring you to retype any password or other
        user identification when you revisit the Europress service. You can
        choose not to take advantage of this feature on your computer by
        unchecking the box "Remember me on this device" when you log in to the
        website.
      </p>
      <p>
        To remove access to your Europress account from your devices: please
        contact europress@europress.fi and request removal of SMART account.
        Where possible, users of public or shared devices should log out at the
        completion of each visit. If you sell or return a computer or Europress
        SMART ready device, you should log out and deactivate the device before
        doing so. If you do not maintain the security of your password or
        device, or fail to log out or deactivate your device, subsequent users
        may be able to access your account, including your personal information.
      </p>
      <h2>Choices</h2>
      <p>
        If you no longer want to receive certain communications from us via
        email or text message, simply send a removal request to
        europress@europress.fi. Please note that you cannot unsubscribe from
        certain email correspondence from us, such as messages relating to your
        account transactions.
      </p>
      <h2>Your Information and Rights</h2>
      <p>
        You can request access to your personal information, or correct or
        update out-of-date or inaccurate personal information we hold about you.
      </p>
      <p>
        You can most easily do this by visiting the "Profile" portion of our
        SMART website, where you have the ability to access and update
        information about your account, including your contact information and
        various related information about your account. You must be signed in to
        access "Profile." You may also request that we delete personal
        information that we hold about you.
      </p>
      <p>
        To make requests, or if you have any other question regarding our
        privacy practices, please contact us at europress@europress.fi. Please
        also see the Your Choices section of this Privacy Statement for
        additional choices regarding your information.
      </p>
      <p>
        We may reject requests that are unreasonable or not required by law,
        including those that would be extremely impractical, could require
        disproportionate technical effort, or could expose us to operational
        risks such as free trial fraud. We may retain information as required or
        permitted by applicable laws and regulations, including to honor your
        choices, for our billing or records purposes and to fulfill the purposes
        described in this Privacy Statement.
      </p>
      <h2>Security</h2>
      <p>
        We use reasonable administrative, logical, physical and managerial
        measures to safeguard your personal information against loss, theft and
        unauthorized access, use and modification. Unfortunately, no measures
        can be guaranteed to provide 100% security. Accordingly, we cannot
        guarantee the security of your information.
      </p>
      <h2>Changes to This Privacy Statement</h2>
      <p>
        We will update this Privacy Statement from time to time in response to
        changing legal, regulatory or operational requirements. We will provide
        notice of any such changes (including when they will take effect) in
        accordance with law. Your continued use of the Europress SMART service
        after any such updates take effect will constitute acceptance of those
        changes. If you do not accept any updates to this Privacy Statement, you
        may cancel your use of the Europress SMART service. To see when this
        Privacy Statement was last updated, please see the "Last Updated"
        section below.
      </p>
      <b>Last updated:</b> 3 April 2018
      <h1>Cookie Policy</h1>
      <p>
        Europress ("us", "we", or "our") uses cookies on
        smart.europressgroup.com (the "Service"). By using the Service, you
        consent to the use of cookies.
      </p>
      <p>
        Our Cookie Policy explains what cookies are, how they are used, how
        third-parties we may partner with may use cookies on the Service, your
        choices regarding cookies and further information about cookies.
      </p>
      <h2>What Are Cookies?</h2>
      <p>
        A cookie is a small text file that a website saves on your computer or
        mobile device when you visit the site. It enables the website to
        remember your actions and preferences (such as login, language, font
        size and other display preferences) over a period of time, so you don’t
        have to keep re-entering them whenever you come back to the site or
        browse from one page to another.
      </p>
      <p>Cookies can be "persistent" or "session" cookies.</p>
      <h2>How Europress Uses Cookies?</h2>
      <p>
        When you use and access the Service, we may place a number of cookies in
        your web browser.
      </p>
      <p>
        We use both session and persistent cookies on the Service and the
        purpose for which they are used, are set out below:
      </p>
      <ul>
        <li>Authenticate users and prevent fraudulent use of user accounts.</li>
        <li>Store user preferences.</li>
        <li>Store user statistics.</li>
        <li>Improve the usability of the Service.</li>
      </ul>
      <b>Third-party Cookies</b>
      <p>
        In addition to our own cookies, we may also use various third-parties
        cookies to report usage statistics of the Service, for example.
      </p>
      <h2>Your Choices</h2>
      <p>
        If you'd like to delete cookies or instruct your web browser to delete
        or refuse cookies, please visit the help pages of your web browser.
      </p>
      <p>
        Please note, however, that if you delete cookies or refuse to accept
        them, you might not be able to use all of the features we offer, you may
        not be able to store your preferences, and some of our pages might not
        display properly.
      </p>
      <h2>More Information</h2>
      <p>
        You can learn more about cookies on{" "}
        <a href="https://allaboutcookies.org/" target="_blank" rel="noreferrer">
          All About Cookies.org.
        </a>
      </p>
      <p>
        <b>Last updated:</b> 3 April 2018
      </p>
    </Page>
  );
}
